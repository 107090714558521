.slick-slider {
  margin-bottom: 40px;
  min-height: 300px;
}

.slick-prev::before, .slick-next::before {
  color: black;
}

.slick-prev, .slick-next {
  top: calc(50% - 40px)
}

.slick-prev:before,
.slick-next:before {
  color: white;
}

.slick-list {
  margin: 0 -5px;
}

.slick-slide { will-change: transform; }

.slick-slide > div {
  padding: 8px;
  
}


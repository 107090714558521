
.poster__image {
  object-fit: contain;
  transition: transform 450ms;
  width: 100%;
  height: 100%;
  will-change: transform; 
  border-radius: 5px;
}

.poster__image:hover {
  transform: scale(1.04);
  opacity: 1;
}

.poster__body {
  min-height: 100px;
}

.test {
  padding-bottom: 4px;
}
.selector {
  background-color: transparent;
  /* background-image: linear-gradient(
90deg
, rgb(71, 16, 193), rgb(120, 87, 255) 85%, rgb(129, 155, 253) 100%); */
  width: min-content;
  border-radius: 30px;
  position: relative;
  margin-bottom: 10px;
  border: 1px solid rgb(71, 16, 193);
  
}

.selector-item {
  white-space: nowrap;
  padding: 0.25rem 1.5rem;
  cursor: pointer;
  z-index: 2;
  user-select: none;
  transition: color 0.2s ease;
  font-weight: 600;
  font-size: .8em;
  color: white;
}

.selector-item--active {
  color: white;
  font-weight: 600;
}

.highlight {
  background-image: linear-gradient(
90deg
, rgb(71, 16, 193), rgb(120, 87, 255) 85%, rgb(129, 155, 253) 100%);
  border-radius: 30px;
  position: absolute;
  /* left: 0.25rem; */
  width: 4.75rem;
  height: 1.7rem;
  z-index: 1;
  /* box-shadow: 0px 0px 12px -2px rgba(255,71,86,0.9); */
  transition: left 0.2s ease,
              width 0.2s ease;
}
.row__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 10px;
  margin-bottom: 60px;
}

.row__poster {
  object-fit: contain;
  transition: transform 450ms;
  border-radius: 8px;
  will-change: transform;
  max-height: 225px;
  margin-right: 10px;
}

.row__poster:hover {
  transform: scale(1.05);
  opacity: 1;
}

.row h5 {
  padding-left: 20px;
}


.carousel__container{
  position: relative;
  width: 100%;
  height: 450px;
  padding: 20px 0;
  overflow-x: hidden;
  margin-top: 10px;
  margin-bottom: 30px;
}

.carousel{
  display: flex;
  width: 92%;
  height: 100%;
  position: relative;
  margin: auto;
}

.carousel div {
  padding: 0px;
}

.slider{
  flex: 0 0 auto;
  margin-right: 30px;
  position: relative;
  background: rgba(0, 0, 0,0.5);
  border-radius: 5px;
  width: 100%;
  height: 100%;
  left: 0;
  transition: 1s;
  overflow: hidden;
}

.slider img{
  width: 70%;
  min-height: 100%;
  object-fit: cover;
  display: block;
  margin-left: auto;
}

.slide__content{
  position: absolute;
  width: 50%;
  height: 100%;
  z-index: 2;
  background: linear-gradient(to right, #030b17 80%, #0c111b00);
  color: #fff;
}

.show__title{
  padding-left: 50px;
  text-transform: capitalize;
  margin-top: 80px;
}

.show__overview{
  width: 80%;
  line-height: 30px;
  padding-left: 50px;
  margin-top: 30px;
  opacity: 0.8;
  font-size: 0.9rem;
}


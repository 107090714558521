.dropdown-toggle::after {
  display: none !important;
}

/* will run on items added after initial mount */
.fade-enter{
  opacity: 0.01;
}
.fade-enter-active{
  opacity: 1;
  transition: opacity 2000ms ease-in 500ms;
}
.fade-enter-done{
  opacity: 1;

}
/* will run on any item being removed */
.fade-exit{
  opacity: 1;
}
.fade-exit-active{
  opacity: 0.01;
  transition: opacity 2000ms ease-in 500ms;
}
.fade-exit-done{
  opacity: 0;
}
/* set to false by default. Will run on initial mount only */
.fade-appear{
  opacity: 0.01;
}
.fade-appear-active{
  opacity: 1;
  transition: opacity 600ms ease-in;
}

@media screen and (min-width: 992px) {
  .container {
    min-width: 95%;
  }
}


@media screen and (max-width: 992px) {
  html {
    font-size: 90%;
  }
}

@media screen and (max-width: 576px) {
  html {
    font-size: 85%;
  }
}

body {
  background-image: url('./assets/images/default_1920x1080.png');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
